.close_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    margin-top: 10px;
}

.ledger_title {
    font-size: 20px;
    font-weight: 500;
}

.ledger_container {
    margin: 20px;
}

.ledger_headCell {
    font-size: 16px;
    font-weight: 600;
    background-color: #F6FBFA;
}

.ledgerTableContainer {
    border: 1px solid lightgray;
    border-radius: 10px;
}
