.ViewMode {
  pointer-events: all;
}

.edit_btn_patientInfo {
  pointer-events: all;
  display: flex;
  flex-direction: row-reverse;
}

.edit_btn_group{
  /* border: 1px solid red; */
  margin-top: 8px;
  display: flex;
  gap: 0.3rem;
}
.save_btn_patientInfo{
  border: 1px solid red;
  /* margin-top: 0px; */
}

.patientInfoSkeleton{
  width: 450px;
}

.input_field_content_wrap{
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.input_field_content{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input_field_view_mode{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 200px; */
  justify-content: space-between;
  padding-bottom: 6px;
}

.label{
  color: #676767;
  font-size: 12px;
}

.copyIcon {
  cursor: pointer;
}
.copyIcon_date {
  position: relative;
  right: 48px;
  top: 12px;
  margin: -12px;
}