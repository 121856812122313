.container_data_create {
    padding: 2rem 3rem;
}

.appointment_dialog-create .MuiDialog-container .MuiPaper-rounded {
    border-radius: 8px !important;
}

.scrollbarY--custom::-webkit-scrollbar {
    width: 0.4em;
    height: 0.6rem;
    border-radius: 0px 10px 0px 0px;
}

.scrollbarY--custom::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.scrollbarY--custom::-webkit-scrollbar-thumb {
    background-color: rgb(182, 182, 182);
    border-radius: 10px 20px 10px 10px;
}