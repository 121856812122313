.patient_info_main_div {
  /* width: 1000px !important; */
  /* width: 600px !important; */
  /* width: 875px !important; */
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  /* padding-bottom: 2rem; */
  /* margin-bottom: 0.9rem; */
  /* border: 1px solid red; */
  border-radius: 8px;
  background-color: white;
  /* pointer-events: none; */
}

.copyIcon {
  cursor: pointer;
}
.copyIcon_date {
  position: relative;
  right: 48px;
  top: 12px;
  margin: -12px;
}
