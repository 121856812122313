.tab_container {
  min-height: 100vh;
  /* height: 100%; */
  overflow-y: hidden !important;
}

.patientName {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.middle {
  display: flex;
  flex-direction: column;
  width: 130px;
}

.first {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabRow {
  width: 100%;
  /* background-color: #bbe4e6; */
  border-radius: 0.2rem;
  /* background-color: #f7f8fa; */
  background-color: #d9ebe7;
  height: 67px !important;
  text-transform: capitalize;
  /* padding-left: 8px; */
  display: flex;
  flex-direction: column;
}

.active_tab {
  width: 100%;
  /* background-color: red; */
  height: 67px !important;
  border-radius: 25px;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
  padding-left: 0;
  padding-right: 0;
}

.left_tab_pagination {
  /* border:2px solid red;   */
  /* width: 150px; */
  padding: 0;
}

.patient_selector_heading_container {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
  display: flex;
  align-items: center;
  padding-right: 3px;
}

.firstRow {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding-top: 5px;
  align-items: flex-end;
  width: 95%;
  text-align: left;
  margin-left: 5px;
  white-space: nowrap;
  font-weight: 500;
  padding-left: 8px;
}

.secondRow {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  gap: 0.5rem;
  width: 95%;
  padding-bottom: 5px;
  margin-left: 5px;
  text-align: left;
  padding-left: 8px;
  color: #759ca4;
}

.open {
  position: fixed;
  top: 0;
  bottom: 0;

  min-height: 100vh;
  /* border: 1px solid gray; */
  padding-right: 15px;
  margin-right: 20px;
  background-color: white;
  /* border-radius: 10px; */
  padding-bottom: 10px;
  max-width: 15%;
  /* height: 200%; */
  z-index: 3;
}

.open.hidden {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.mainSectionHidden {
  display: none;
}

.dropdown_header {
  font-size: 20px;
  border-bottom: 1px solid lightgray;
  margin-inline: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding-block: 20px;
}

.collapsed_menu_open {
  padding-top: 10px;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  margin-right: 20px;
  position: fixed;
}

.collapsed_menu_hidden {
  display: none;
}

@media (max-width: 1872px) {
  .open {
    margin-right: 20px;
    background-color: white;
    padding-bottom: 10px;
    z-index: 3;
  }

  .firstRow {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding-top: 5px;
    align-items: flex-end;
    width: 95%;
    text-align: left;
    margin-left: 5px;
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
    padding-left: 8px;
  }

  .secondRow {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    gap: 0.5rem;
    width: 95%;
    padding-bottom: 5px;
    margin-left: 5px;
    text-align: left;
    padding-left: 8px;
    color: #759ca4;
    font-size: 12px;
  }

  .dropdown_title {
    /* font-size: 16px; */
  }

  .dropdown_header {
    font-size: 14px;
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
}