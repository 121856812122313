.ins_field_set {
  display: flex;
  width: 120%;
  flex-direction: row;
  /* width: 90%; */
}

.insurance_addicon {
  /* border: 1px solid red; */
  cursor: pointer;
  position: relative;
  bottom: -20px !important;
  right: 75px !important;
}

.insuranceName {
  /* border: 1px solid red !important; */
  /* padding-right: 5px !important; */
  width: 130px !important;
}

.ranking {
  width: 80px !important;
  /* border: 1px solid red !important; */
  margin-right: 200px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input_field_content_wrap {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.input_field_content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input_field_view_mode {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 200px; */
  justify-content: space-between;
  padding-bottom: 6px;
}

.label {
  color: #676767;
  font-size: 12px;
}

.btnGroup {
  display: flex;
  justify-content: right;
  gap: 0.2rem;
}

.edit_btn {
  margin-top: 10px !important;
}

.insurance_addicon {
  cursor: pointer;
  position: relative;
  bottom: -21px;
  right: 51px;
}

.groupNo {
  /* border: 1px solid red !important; */
  width: 117px !important;
  padding: 2px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ranking {
  /* border:2px solid red !important; */
  width: 90px;
}

.insurance_closeIcon {
  cursor: pointer;
  position: relative;
  right: 35px;
  bottom: -19px;
}

.patientinfo_button {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  padding: 1rem 0;
}

.buttonWrapper {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
  margin-bottom: 15px;
  /* margin-left: 100px; */
}

.input_material_status {
  width: 100px !important;
}

.status_view_mode {
  /* border: 2px solid red !important; */
  width: 90px;
  margin-left: 20px;
  align-items: center;
}

.edit_btn_Info {
  /* margin-top: 10px; */
  /* display: flex; */
  /* margin: auto; */
  /* justify-content: space-around; */
  /* margin-left: -35px; */
}
.edit_btn_policyInfo {
  margin-top: 10px;
}
.input_material_field_policy {
  /* border:2px solid red !important; */
  width: 150px;
}
