.ViewMode {
  /* pointer-events: none; */
}

.edit_btn_patientInfo {
  pointer-events: all;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 35px;
}

.addressLine{
  /* border:1px solid red !important; */
  width:200px;
 white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.holder_name{
  width: 250px !important;
}