/* ---------------- LATEST CSS CODE -------------------- */
.filter_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 1rem 0.5rem;
  gap: 10px;
}

.filter_container_block {
  display: flex;
}

.filter_container_block--main {
  display: flex;
}

.filter_container_block--inner {
  display: flex;
}

.filter_field {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 110px; */
  min-width: 95px;
  max-width: 95px;
  /* min-width: 100px;
  max-width: 100px; */
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  border: 1px solid lightGray;
  padding: 10px;
  /* width: 75%; */
  border-radius: 4px;
}
.update_field {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  display: flex;
  /* width: 20%; */
  border-radius: 4px;
  padding: 10px;
  border: 1px solid lightGray;
}

.filter_field_date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  /* min-width: 107px;
  max-width: 107px; */
}

.filter_field_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 150px; */
  padding-top: 10px;
}

.filter_btn {
  padding-right: 2rem;
}

.submit_button {
  margin-top: 19px;
}

@media (max-width: 1600px) {
  .filter_field {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 95px;
    max-width: 95px;
  }

  .filter_field_date {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 117px;
    max-width: 117px;
  }

  .filter_btn {
    padding-right: 1rem;
  }
}
