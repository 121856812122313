.clinic__header {
  display: flex;
  justify-content: center;
  /* border: 2px solid green; */
  width: 100%;
  top: 0;
  z-index: 10;
  background: #fff;
  /* padding: 0px 1rem; */
  justify-content: space-around;
  height: 80px;
  font-weight: 500 !important;
  border-bottom: 1px solid #d7e0e9;
  position: sticky;
}

.hide {
  display: none;
  margin: 0;
}

.logout {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
}



.buttonWrappers {
  /* border: 2px solid red; */
  /* padding: 2px; */
  display: flex;
  justify-content: right;
  flex-direction: row;
  width: 40%;
}

.column_filter_container {
  padding: 10px 10px 10px 20px;
}

@media (max-width: 1520px) {
  .clinic__header {
    justify-content: space-between;
  }
}

.clinic__header__title {
  font-size: 1em;
  padding: 20px 0px;
  font-weight: 500;
}

.clinic__header--inner {
  display: flex;
  align-items: center;
  justify-content: left;
  /* align-items: flex-start; */

}

.clinic__header--inner ul {
  display: flex;
  list-style-type: none;
  gap: 0.3rem;
}

.clinic__header--inner ul li {
  font-size: 21px;
  cursor: pointer;
  position: relative;
  padding: 10px 8px;
  font-weight: 600;
  color: black;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.header-left-insidePage {
  /* padding-left: 24px; */
  /* border: 2px solid red; */
  /* border: 2px solid blue; */
  margin-left: 20px;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10rem;
  white-space: nowrap;
}

.header-left-homePage {
  padding-left: 24px;
  /* border: 2px solid red; */
  /* border: 2px solid blue; */
  width: 65%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: flex-start;
}

.header-right-insidePage {
  /* padding-right: 1rem; */
  width: 30%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}

.header-right-homePage {
  padding-right: 1rem;
  width: 35%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-right: 100px; */

}

.chat__unreadIndicator {
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  background: #2f80ed;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  font-size: 0.8rem;
  margin-left: 4px;
}

.iconFont {
  font-size: 32px !important;
}

.clinic__header__logoutBtn {
  margin: 0px 10px !important;
}

.clinic__header__logoutBtn>span {
  padding: 8px;
  color: var(--primary-green);
  border-radius: 6px;
  font-size: 0.95em;
  transition: 0.3s ease all;
}

.clinic__header__logoutBtn:hover>span {
  background: var(--primary-green);
  color: #fff;
}

.clinic__profileCircle {
  width: 40px;
  height: 40px;
  background: #e0f0f2;
  color: #139696;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.2s ease all;
}

.clinic__profileCircle:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

/***  Popover styles ***/
.clinicHeader__popover {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.clinicHeader__popover--name {
  font-weight: 600;
  white-space: nowrap;
}

.clinicHeader__popover--list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.4rem;
  color: var(--color-grey-700);
}

.clinicHeader__popover--list div {
  cursor: pointer;
}

.clinicHeader__popover--list .logout {
  color: var(--primary-green--dark);
}

.edit-screen {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* border: 2px solid red; */
  /* width: 80%; */
}

/********/
@media (max-width: 1440px) and (min-width: 900px) {
  .clinicHeader .MuiTypography-h4 {
    font-size: 1.75rem;
  }

  .iconFont {
    font-size: 32px !important;
  }

}

@media (min-width:1720px) {
  .header-right-insidePage {
    /* padding-right: 1rem; */
    width: 35%;
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
  }

  .header-left-insidePage {
    /* padding-left: 24px; */
    /* border: 2px solid red; */
    /* border: 2px solid blue; */
    margin-left: 20px;
    width: 75%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: left;
    gap: 20rem;
    /* padding-right: 20px; */
    /* padding-right: 90px; */
  }
}
