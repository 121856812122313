.closeBtn{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 6px;
    padding-left: 10px;
    align-items: center;
    /* padding-right: 50px; */
}

.container{
    /* border: 2px solid red; */
    margin-bottom: 20px;
}

.noDataImage{
    width: 500px;
}

.noData{
    /* border: 2px solid red; */
    width: 700px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}