.callhistory_main_div {
  padding: 2rem;
  border-radius: 8px;
  background-color: white;
}

.notes_date {
  font-size: 15px !important;
  color: rgb(61, 58, 58) !important;
}

.homeScreen_notes_container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 500;
}

.notes_owner {
  font-size: 15px !important;
  max-width: 150px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.notes_content {
  font-size: 15px !important;
}
