.create_prior_auth_btn2 {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  align-items: center;
  height: 100%;
  width: 100%;
}
.new_payer_container{
  padding: 1rem 2rem;
}