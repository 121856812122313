/* .physician_info_main {
  width: 1350px;
  padding: 2rem;
  border-radius: 8px;
  background-color: white;
  pointer-events: none;
} */

.input_material_field{
 white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.input_field_content{
  cursor:pointer
}

.physician_info_main {
  /* width: 600px; */
  padding: 2rem;
  /* border: 1px solid #c6c6c6; */
  border-radius: 8px;
  background-color: white;
  /* pointer-events: none; */
}

.disabled_section {
  /* pointer-events: none; */
}

.physician_button {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  height: 40px;
  white-space: nowrap;

}

