.claim_summary_main_div {
  padding: 2rem;
  border-radius: 8px;
  background-color: white;
}

.claim_summary_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  text-align: center;
}

.claim_summary_header_buttons {
  display: flex;
  gap: 0.5rem;
}