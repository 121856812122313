.precedure_details_accordion {
  display: block !important;
}

.procedure_cpt_table {
  padding: 1rem 0;
  /* border: solid 1px #c6c6c6;
  border-radius: 10px; */
}
.subheading_procedure_accordion {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.subheading_procedure_accordion p {
  font-size: 18px;
}

.radioButtons{
  display: flex !important;
  flex-direction: row !important;
}

.procedure_button {
  display: flex;
  /* border: 2px solid red; */
  height: 40px;
  flex-direction: row-reverse;
  gap: 0.5rem;
  /* padding: 1.5rem 0 1rem 0; */
}

.loader{
  color: white !important;
}

.copyIcon {
  cursor: pointer;
}
.copyIcon_date {
  position: relative;
  right: 48px;
  top: 12px;
  margin: -12px;
}

.submitButton{
  position: relative;
  top: -55px;
}

.item{
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 98%;
  margin: 2px ;
  margin-top: 5px;
  margin-bottom: 5px;
  /* border: 1px solid red; */
}
.finalField{
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  /* width: 98%; */
  margin: 2px;
  margin-top: 6px;
  margin-bottom: 6px;
  /* border: 2px solid green; */
  font-weight: 600;
}

.TreeItem_conatianer{
  /* border: 2px solid red; */
  /* overflow-x: hidden; */
  /* text-overflow: ellipsis; */
  display: flex;
  align-items: center;
}

.procedure_info_main_div {
  width: 1050px;
  padding: 2rem;
  /* border: 1px solid #c6c6c6; */
  border-radius: 8px;
  background-color: white;
}

.header_container{
  /* border: 1px solid red; */
  padding-left: 42px;
  padding-top: 15px;
}

.icd_search_bar{
  /* border: 1px solid red !important; */
  width: 500px !important;
}

.main_container{
  padding: 0px 0px 0px 0px;
}

.category_main_container{
  /* border: 1px solid lightgray; */
  margin-left: 32px;
  margin-right: 32px;
  /* padding: 10px 0px 0px 0px; */
  margin-bottom: 80px;
}

.category_btn{
  margin-left: 50px;
}

.category{
  border: none;
}

.icd_search_wrap{
  overflow: hidden;;
  height: 75vh;
}

.tree_container{
  /* border: 2px solid lightgray; */
   height: 600px;
  padding: 10px;
  /* margin-bottom: 20px; */
  /* margin-bottom: 10px; */
  overflow-y: scroll;
}

.no_data_found{
  display: flex;
  align-items: center;
  justify-content: center;
}

.chipListContainer{
  /* border: 2px solid red; */
  display: flex;
}

.chipList{
  /* border: 1px solid green; */
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  gap: 0.4rem;
}

.result_container{
  /* height: 620px; */
  height:50vh;
  border: 2px solid lightgray;
  border-radius: 10px;
  margin-top: 5px;
  padding: 10px;
  padding-top: 0;
  margin-bottom: 10px;
  overflow-y: scroll;
  /* overflow-x: hidden; */
  /* text-overflow: ellipsis; */
}

.icd_chip{
  margin: 0px 0px 0px 10px;
}

.header{
  /* border: 2px solid red;  */
  display: flex;
  justify-content: space-between;
}



.searchField{
  /* border: 1px solid red; */
  display: flex;
  gap: 1rem;
  align-items: center;
}

.search_select_container{
  /* border: 1px solid red; */
  width: 300px;
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  padding-top: 15px;
  padding-left: 10px;
  height: 60px;
}

.addButton{
  display: flex;
  flex-direction: row-reverse;
  padding-right: 100px !important;
}

.search_btn{
  height: 55px;
  width: 120px;

}

.procedureHeader{
  display: flex;
  align-items:center ;
  /* border: 2px solid green; */
  margin-bottom: 20px;
  justify-content: space-between;
  align-content: center;
  text-align: center;
}

.input_material_field_icdSearch{
  /* border: 2px solid red !important; */
  width: 30rem !important;
}
