.callinfo_button {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  padding: 1rem 0;
}

.paymentSummary_main_div {
  padding: 2rem;
  border-radius: 8px;
  background-color: white;
  padding-right: 1rem;
}

.nodata {
  border: 1px solid lightgray;
  border-radius: 8px;
  height: 340px;
}

.button_container {

  display: flex;
  align-items: center;
}

.text_button {
  width: 65px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  color: #14a6a6 !important;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.expand_cell {
  padding: 0 !important;
}

.expand_container {
  padding: 0.7rem 1.2rem;
  background-color: #fafbfd;
}

.arrow_icon {
  cursor: pointer;
  padding: 16px 0 10px 16px !important;
  color: #697386 !important;
}

.expand_first_card {
  background-color: white;
  border-left: none;
  display: flex;
  justify-content: space-between;
  padding-right: 14px;
}

.expand_second_card {
  margin-top: 5px;
  margin-bottom: 5px;
}

.expand_card_item {
  color: #1a1f36;
  font-weight: 600;
  margin-right: 10px;

  min-width: 90px;
}

.expandItem {
  display: flex;
  background-color: white;
  border-left: none;
  padding: 0.8rem 0.6rem;
  display: flex;
}

.headingCell {
  white-space: nowrap;
  font-weight: 600 !important;
  padding-top: 25px !important;
  font-weight: bold !important;
}

.tableCell {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75px;
  padding: 0 !important;
  padding-left: 9px !important;
  padding-right: 5px !important;
}

.buttonCell {
  max-width: 90px;
}

.table_viewBtn {
  cursor: pointer;
  color: #14a6a6 !important;
  text-decoration: underline;
  text-underline-offset: 3px;
  display: flex;
  align-items: center;
}

.heading {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.buttongroup {
  display: flex;
  max-height: 40px;
  white-space: nowrap;
  justify-content: space-around;
}

.tableWrap {
  border: solid 1px #c6c6c6;
  border-radius: 8px !important;
  overflow-x: auto;
}