.tabButtonGroup1{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.7rem;
  /* justify-content: space-evenly; */
  /* border: 2px solid red; */
  /* margin-bottom: 20px; */
  /* width: 500px; */
  width: fit-content;
}
.tabsButtonGroup2{
  /* border: 2px solid green; */
  display: flex;
  gap: 1rem;
}   

.buttonContainer{
  display: flex;
  /* border: 2px solid red; */
  gap: 1rem;
  flex-direction: column;
}

.tabButtons{
  color: black !important;
  height: 48px;
  width: 90px;
  white-space: nowrap;
  font-size: 16px !important;
  box-shadow: none;
  color: white !important;
}

.button_active{
  background-color: white !important;
  color: black !important;
  height: 48px;
  width: 90px;
  white-space: nowrap;
  font-size: 16px !important;
  box-shadow: none;
}

.tab_panel_container{
  
}

.notes_container{
  /* border: 1px solid red; */
  width: 100%;
}

.patient_container {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.physician_container{
  margin-top: 10px;
}
