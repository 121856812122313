.precedure_details_accordion {
  display: block !important;
}
.procedure_cpt_table {
  padding: 1rem 0;
  /* border: solid 1px #c6c6c6;
  border-radius: 10px; */
}
.subheading_procedure_accordion {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.subheading_procedure_accordion p {
  font-size: 18px;
}

.procedure_button {
  display: flex;
  /* border: 2px solid red; */
  height: 40px;
  flex-direction: row-reverse;
  gap: 0.5rem;
  /* padding: 1.5rem 0 1rem 0; */
}

.loader{
  color: white !important;
}

.copyIcon {
  cursor: pointer;
}
.copyIcon_date {
  position: relative;
  right: 48px;
  top: 12px;
  margin: -12px;
}

.procedure_info_main_div {
  width: 100%;
  /* width: 1050px; */
  padding: 2rem;
  /* border: 1px solid #c6c6c6; */
  border-radius: 8px;
  /* border: 2px solid green; */
  background-color: white;
}

.procedureHeader{
  display: flex;
  align-items:center ;
  margin-bottom: 20px;
  justify-content: space-between;
  align-content: center;
  text-align: center;
}
