
   .upload_progression {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 110px;
        height: 100px;
  
    }
    .upload_progression p {
        font-weight: 500;
        font-size: 20px;
    }

    .upload_button{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }