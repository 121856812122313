.MuiTableSortLabel-root {
  color: grey !important;
}

.table_icon {
  /* padding-top: 10px; */
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  /* border: 2px solid red; */
  /* min-width: 410px; */
}

.iconButtons {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}

.tableRow {
  cursor: pointer;
  height: 20px !important;
}

.faxIcon {
  filter: invert(45%) sepia(70%) saturate(491%) hue-rotate(131deg) brightness(92%) contrast(95%);
}

.payor_name {
  max-width: 100px;
  /* border :2px solid red; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appt_type {
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.underLineButton {
  cursor: pointer;
  color: #14a6a6 !important;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.greyButton {
  color: grey;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.table_editBtn {
  margin-right: 10px;
  cursor: pointer;
}

.table_logBtn {
  cursor: pointer;
  margin-right: 10px;
}

.coder_table_list {
  min-height: 700px !important;
}

.MuiTableCell-root {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.MuiTablePagination-root {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.MuiTableSortLabel-root {
  color: gray;
}

.MuiSvgIcon-root {
  color: #828282;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
  padding-left: 152px;
  padding-right: 152px;
}

.MuiTablePagination-root {
  border-radius: 10px;
  border: solid 1px #e0e0e0;
}

.dataTable_head {
  background-color: #f2fbf9;
}

.dataTable_head_row .data_table_list_cell {
  padding: 2px !important;
}

.data_table_list_cell {
  border-color: #d7e0e9 !important;
  white-space: nowrap;
  height: 20px !important;
}

.iconCell {
  /* border: 2px solid red !important; */
  /* min-width: 800px; */
}

.data_table_list_cell.MuiTableCell-root.MuiTableCell-body {
  padding: 0 10px 0 0 !important;
}

.data_table_dropdown {
  max-width: 120px;
  border-color: #d7e0e9 !important;
}

.data_table_dropdown.MuiTableCell-root {
  padding: 0 10px !important;
}

.table_checkbox {
  max-width: 40px;
  padding-left: 10px !important;
}

.nodatafound {
  height: 675px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointment_add_icon {
  font-size: 4rem !important;
  cursor: pointer;
  position: fixed;
  top: 89%;
  right: 0px;
  left: 93%;
  bottom: 0px;
}

@media (max-width: 1600px) {
  .data_table_list_cell {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-color: #d7e0e9 !important;
  }

  .clinic_name_table_row {
    max-width: 80px !important;
  }
}
