.callhistory_main_div {
  /* width: 1350px; */
  padding: 2rem;
  /* border: 1px solid #c6c6c6; */
  border-radius: 8px;
  background-color: white;
}

.notes_date {
  font-size: 15px !important;
  color: rgb(61, 58, 58) !important;
}

.notes_owner {
  font-size: 15px !important;
  max-width: 150px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.notes_content {
  font-size: 15px !important;
}
.notes_form_container {
  display: flex;
  padding-bottom: 1rem;
}
.notes_form_input {
  width: 75% !important;
  margin-right: 10px;
}
.notes_form_button {
  width: 25%;
  display: flex;
  gap: 10px;
  align-items: center;
}
