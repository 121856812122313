.pa_history_main {
  padding: 3rem;
  min-height: 700px !important;
}
.nodatafound {
  height: 540px;
}
.pa_history_btns {
  display: flex;
  justify-content: flex-end;
}
