.diagnosis_main_div p {
  font-size: 18;
  font-weight: 500;
}

.diagnosis_heading {
  padding-bottom: 1rem;
}

.diagnosis_grid {
  /* border: 2px solid red; */
  width: 100% !important;
}

.dx_field_set {
  display: flex;
  /* width: 240px; */
  /* width: 190px; */
  /* margin-right: -68px; */
  width: 124%;

}

.dx_addicon {
  cursor: pointer;
  position: relative;
  bottom: -21px;
  right: 38px;
}
.dx_searchicon {
  cursor: pointer;
  position: relative;
  right: 38px;
  bottom: -21px;
}
.dx_closeIcon {
  cursor: pointer;
  position: relative;
  right: 39px;
  bottom: -21px;
}

.MuiTableCell-sizeSmall {
    padding: 0px 0px 0px 0px !important;
}
