* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* background-color: #c7c7c7; */
}

body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
.MuiGrid-container {
  margin-bottom: 5px !important;
}
.MuiButton-root {
  text-transform: none !important;
}
/* .MuiPaper-elevation1 {
  box-shadow: none !important ;
} */
.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.MuiAutocomplete-popupIndicator {
  display: none !important;
}
.MuiAutocomplete-clearIndicator {
  display: none !important;
}
