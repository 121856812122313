.head_info_container {
  padding: 3rem 4.5rem 0 4.5rem;
}

.head_info_container p {
  font-weight: 500;
  font-size: 1.5rem;
}

.container_min {
  display: flex;
  flex-direction: column;
  width: 84%;
  padding: 0;
}

.container_max {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.wrapper {
  /* border: 2px solid red; */
  /* background-color: lightblue; */
  display: flex;
  /* flex-direction: column; */
}

.leftTabOpen {
  background-color: #c7c7c738;
  /* padding-left: 1rem; */
  /* padding-top:1.5rem; */
  width: 16%;
}

.leftTabClosed {
  background-color: #c7c7c738;
  height: 100vh;
  /* border: 1px solid red; */
  width: 3.5%;
}

.left_tab_inner_wrap {
  /* position: absolute; */
  /* left: 10px; */
  height: 100vh;
}

.edit_section_button_container {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;

}

.edit_ection_button {
  background-color: white !important;
  color: black !important;
  height: 48px;
  /* width: 90px; */
  font-size: 16px !important;
  box-shadow: none !important;
}

.edit_ection_button_disabled {
  background-color: #D3D3D3 !important;
  color: black !important;
  height: 48px;
  /* width: 90px; */
  font-size: 16px !important;
  box-shadow: none !important;
}

.tabButtonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 2px solid red; */
  width: 60%;
}

/* .tabButtons{
  background-color: white !important;
  height: 60px;
  width: 140px;
  font-size: 22px !important;
  box-shadow: none;
} */

.tabTitle {
  display: flex;
  padding: 10px;
  margin-top: 10px;
  justify-content: space-between;
  /* border: 2px solid red; */
  text-transform: capitalize;
  background-color: rgb(174, 255, 255);
  width: 100%;
  margin-left: 25px;
  border-bottom: 1px solid lightgray;
}

.tabRow {
  display: flex;
  justify-content: space-between;
  /* border: 2px solid red; */
  width: 100%;
  padding: 10px;
  text-transform: capitalize;
  border-bottom: 1px solid lightgray;

  margin-left: 25px;
}

.mainContainer {
  display: flex;
  /* border: 2px solid red; */
  width: 100%;
}

.preview {
  width: 100% !important;
  height: 100vh !important;
  overflow: hidden !important;
  /* padding-top: 10px !important; */
}

.preview_closeBtn {
  margin-bottom: 5px;
  cursor: pointer;
  margin-left: 5px;
}

.previewDialogContent {
  overflow: hidden !important;
  height: 100%;
  padding-bottom: 10px !important;
}

.previewDialogContent::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.main_edit_section {
  background-color: #c7c7c738;
  padding-top: 1.5rem !important;
  /* padding-left: 10px; */
  padding-right: 10px;
  /* border: 2px solid red; */
  /* width: 47.5%; */
  width: 50%;
}

.rightTab {
  background-color: #c7c7c738;
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 500px;
  width: 50%;
}

.main_edit_section_drawer_open {
  background-color: #c7c7c738;
  padding-top: 1.5rem !important;
  /* padding-left: 10px; */
  padding-right: 10px;
  /* width: 41%; */
}

.right_tab_drawer_open {
  background-color: #c7c7c738;
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 500px;
  /* width: 41%; */
  width: 50%;
}

.patient_info_container {
  display: flex;
  width: 600px;
  justify-content: center;
  /* padding: 1rem 0; */
  /* padding: 20px 70px 0px 70px; */
}

.physician_info_container {
  display: flex;
  width: 600px;
  justify-content: center;
  padding: 1rem 0;
  /* padding: 20px 70px 0px 70px; */
}

.notes_info_container {
  display: flex;
  width: 600px;
  justify-content: center;
  /* padding: 1rem 0; */
  /* padding: 20px 70px 0px 70px; */
}

.call_info_container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  min-width: 1350px;
  width: 100%;
  /* padding: 20px 70px 0px 70px; */
}

.payor_info_container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  /* padding: 20px 70px 0px 70px; */
}

.procedure_info_container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.edit_ui_heading {
  display: flex;
  gap: 1rem;
  padding: 10px;
}

.MuiAccordionDetails-root {
  display: block !important;
}


.componentWrapper {
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.section {
  margin-bottom: 18px;
}

.accordion {
  margin-bottom: 20px;
}

.accordion .MuiAccordionSummary-root {
  background: #ffffff;
  border-radius: 12px;
  padding: 0rem 2rem;
  display: flex;
  align-items: flex-start;
}

.accordion .MuiAccordion-root {
  margin-bottom: 10px;
  border-radius: 12px;
  position: inherit;
}

.MuiPaper-elevation1 {
  /* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); */
  box-shadow: none !important;
}

.MuiAccordion-root:before {
  display: none;
}

.accordion .MuiAccordionSummary-root.Mui-expanded {
  min-height: 64px;
  border-radius: 12px 12px 0px 0px;
  box-shadow: none !important;
  border: 2px solid red;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: 25%;
  right: 0px;
  width: 75%;
}

.MuiIconButton-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  border: 2px solid red;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* border-radius: 50%; */
}

.MuiAccordion-root.Mui-expanded {
  margin: 16px 0;
}


.sum {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
}

.button_wrapper {
  margin-right: 10px;
}

.header_button_container {
  display: flex;
  /* border: 2px solid red; */
  align-items: flex-end;
  justify-content: flex-end;
}

.summary {
  padding-top: 15px;
}


.show {
  display: block;
  padding-top: 15px;
  /* border:2px solid red; */
  width: 100%;
}

.hide {
  display: none;
}
